<template>
  <article class="contents">
    <div class="medical-trend-wrap">
      <div class="search-wrap"><!-- display:flex -->
        <div class="left-col">
          <div class="select-wrap">
            <select v-model="query.searchType">
              <option
                v-for="option in codes.searchType"
                :key="option.code"
                :value="option.code"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="search-bar">
            <div class="text-wrap">
              <input
                v-model="query.keyword"
                type="text"
                placeholder="키워드를 입력하세요."
                class="search-type"
                @keyup.enter="fetchMedicals()"
              >
              <button class="btn-search">
                검색
              </button>
            </div>
          </div>
        </div>
        <div class="right-col">
          <div class="label-medical">
            <label
              v-if="!query.isOnlyScrap"
              class="scrap-type"
              @click="toggle"
            >스크랩한 글만 보기
            </label>
            <label
              v-else
              @click="toggle"
            >목록으로
            </label>
          </div>

          <div class="select-wrap">
            <select
              v-model="query.orderingType"
              @change="changeOrderType"
            >
              <option
                v-for="option in codes.orderingType"
                :key="option.code"
                :value="option.code"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- 썸네일 리스트 -->
      <div class="thumbnail-wrap">
        <!--
              게시글 게시기간이 지나지 않음(메디컬트렌드, 일부 약품정보) -> 클릭(열람) 가능
              게시글 게시기간이 지남(일부 약품정보) -> 클릭(열람) 불가
            -->
        <div class="thumbnail-list">
          <div
            v-for="item in medicalTrends"
            :key="item.liveInfoId"
            class="thumbnail-item"
            @click="openMedicalModal(item.liveInfoId, 148)"
          >
            <dl>
              <dt>{{ item.title }}</dt>
              <dd class="s-txt">
                {{ item.subText }}
              </dd>
              <dd class="info">
                <span class="date">{{ item.startDate | $dateFormatter('YYYY-MM-DD') }}</span>
                <span class="view">조회 <em>{{ item.viewCount }}</em></span>
                <span
                  v-if="item.isComment"
                  class="reply"
                >댓글 <em>{{ item.commentCount }}</em></span>
              </dd>
            </dl>
            <!--
                  width: 214px;
                  height: 208px;
              -->
            <div class="thumbnail-img">
              <img
                :src="item.filePath"
              >
            </div>
            <div
              v-show="item.isScrapped"
              class="thumbnail-label"
            />
          </div>
        </div>
      </div>
      <pagination
        :page="currentPage"
        :records="totalRecord"
        :per="pageViewCount"
        @paging="pageUpdate"
      />
    </div>
    <popup-medical-view
      ref="popupMedicalView"
      :live-info-ids="liveInfoIds"
      @fetchMedicals="fetchMedicals"
      @statUpdate="statUpdate"
    />
    <confirm-dialog :options="confirmProps" />
    <alert-dialog :options="alertProps" />
  </article>
</template>

<script>
import axios from '@axios'
import Pagination from '@/components/controls/Pagination.vue'
import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'
import { setStatistics, setStatisticsAwait } from '@/common/statistics/service'
import PopupMedicalView from "./PopupMedicalView.vue"

export default {
  components: {
    Pagination,
    PopupMedicalView,

  },
  props: {
  },
  data() {
    return {
      medicalTrends: [],
      orderType: 1, // 최신순,조회순,댓글순
      query: {
        keyword: null,
        searchType: 1,
        orderingType: 1,
        page: 1,
        userLoginId: '',
        isOnlyScrap: false,
      },
      codes: {
        searchType: [],
        orderingType: [],
      },
      currentPage: 1,
      totalRecord: 0,
      pageViewCount: 6,
      liveInfoIds: [],
      user: {
        userLoginId: '',
      },
      // 진입 통계 ID (ReferenceId로 사용)
      entry: null,
      entryStatsId: null,
    }
  },
  computed: {
    ...mapGetters({
      params: 'util/getParams',
      infoSetting: 'infoData/getInfoSetting',
    })
  },
  watch: {
    params() {
      if (this.params && this.params.route === this.$route.name) this.openMedicalModal(this.params.id, this.params.entry)
    },
    currentPage() {
      this.fetchMedicals()
    },
    infoSetting() {
      this.fetchMedicals()

      this.user = getUserData()

      if (this.$route.query.id) this.openMedicalModal(this.$route.query.id, this.$route.query.entry)
      else if (this.$route.params.id) this.openMedicalModal(this.$route.params.id, this.$route.params.entry)
      else if (this.params) this.openMedicalModal(this.params.id, this.params.entry)
    },
    // eslint-disable-next-line func-names
    '$route.query.id': function (newId) {
      this.openMedicalModal(newId)
    }
  },
  mounted() {
    this.fetchCodes()

    if (this.infoSetting) {
      this.fetchMedicals()
      this.user = getUserData()

      if (this.$route.query.id) this.openMedicalModal(this.$route.query.id, this.$route.query.entry)
      else if (this.$route.params.id) this.openMedicalModal(this.$route.params.id, this.$route.params.entry)
      else if (this.params) this.openMedicalModal(this.params.id, this.params.entry)
    }
  },
  methods: {
    fetchCodes() {
      axios.get("/fu/medical/codes")
        .then(rs => {
          const { searchType, orderingType } = rs.data

          this.codes.searchType = searchType
          this.codes.orderingType = orderingType
        })
        .catch(() => {
          this.showAlertDialog('조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    fetchMedicals() {
      // 로그인 아이디 획득
      if (this.user && this.user.userLoginId) {
        this.query.userLoginId = this.user.userLoginId
      } else {
        this.query.userLoginId = ''
      }

      axios.post("/fu/medical/list", {
        search: {
          query: this.query,
        },
        sort: {
          predicate: 'liveInfoId',
          reverse: true,
        },
        pagination: {
          number: this.currentPage,
          count: this.pageViewCount,
        },
      })
        .then(rs => {
          const { items, totalRecord, liveInfoIds } = rs.data
          this.medicalTrends = items
          this.totalRecord = totalRecord
          this.liveInfoIds = liveInfoIds
        })
        .catch(() => {
          this.showAlertDialog('조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    changeOrderType() {
      this.fetchMedicals()
    },
    pageUpdate(page) {
      this.currentPage = page
    },
    toggle() {
      this.query.isOnlyScrap = !this.query.isOnlyScrap
      this.fetchMedicals()
    },
    async openMedicalModal(id, entry = 149) {
      this.medicalTrends = null
      const statsId = await setStatisticsAwait(entry, id, this.entryStatsId)

      if (!this.entry) this.entry = Number(entry)
      if (!this.entryStatsId) this.entryStatsId = statsId

      this.$refs.popupMedicalView.openMedicalModal(id)
      if (this.params) this.$store.commit('util/setParams', null)
    },
    statUpdate(id) {
      if (this.entry) setStatistics(this.entry, id, this.entryStatsId)
    }
  },
}
</script>

<style scoped>
</style>
